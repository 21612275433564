'use client';

import { memo } from 'react';
import Container from '~/components/Layouts/Container';
import RenderPosts from '~/components/Renders/RenderPosts';

const MainPage = memo(() => {
  return (
    <>
      <Container>
        <RenderPosts random />
      </Container>
    </>
  );
});

export default MainPage;
